@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(./assets//icons/calendar.svg) no-repeat center;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  border-width: thin;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="datetime-local"] {
  pointer-events: auto;
}

.ag-cell {
  border: none !important;
  box-shadow: inset 0 -0.5px 0 0 #f1f1f4, inset 0 0.5px 0 0 #f1f1f4,
    inset 0.5px 0 0 0 #f1f1f4, inset -0.5px 0 0 0 #f1f1f4;
}

.ag-row {
  border: none !important;
}
.ag-cell-focus {
  border: 1px solid #666cff !important;
  box-shadow: none !important;
}

.ag-cell-range-selected {
  border: 1px solid #666cff !important;
  border: 1px solid !important;
}
.custom-header {
  color: var(--Gray-Gray-600, #78829d);
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1em;
  font-size: clamp(8px, 1.5vw, 12px);
}
.header-ellipsis .ag-header-cell-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ag-theme-quartz .ag-header-cell-text {
  color: var(--Gray-Gray-600, #78829d) !important;
  font-family: "SF Pro Display", sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;

  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "SFProDisplay", sans-serif;
}

.loader {
  animation: spin 1s linear infinite;
  border: 4px solid #666cff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 58px;
  height: 58px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
